<template lang='pug'>
v-row.mt-10
  v-container.fill-height(fluid, v-if="!loggedin")
    v-row(align="center", justify="center")
      v-col(cols="12", sm="8", md="4")
        v-card.elevation-12
          v-toolbar(color="primary", dark, flat)
            v-toolbar-title New Power Trip Account
            v-spacer
          v-card-text
            v-form(ref="form", v-model="valid", lazy-validation)
              v-text-field(
                :label="nameLabel",
                name="userName",
                prepend-icon="mdi-account",
                type="text",
                v-model="userName",
                :rules="[rules.required, rules.tiny]"
              )
              v-text-field(
                :label="emailLabel",
                name="email",
                prepend-icon="mdi-email",
                type="text",
                v-model="email",
                autocomplete="username",
                :rules="[rules.required, rules.email]"
              )
              v-row
                v-col(cols="1")
                  v-icon mdi-phone
                v-col(cols="11")
                  vue-phone-number-input(
                    v-model="phone",
                    clearable,
                    prepend-icon="mdi-email"
                  )
              v-text-field#password(
                label="Password",
                name="password",
                prepend-icon="mdi-lock",
                type="password",
                v-model="password",
                autocomplete="current-password",
                :rules="[rules.required, rules.minimum]"
              )
              v-text-field#cpassword(
                label="Confirm Password",
                name="password",
                prepend-icon="mdi-lock",
                type="password",
                v-model="confirmPassword",
                autocomplete="current-password",
                :rules="[rules.confirmPassword]"
              )
            v-card-actions
              v-spacer
              v-btn(text, @click="login" v-if="!dealerMode") Use Existing Account
              v-btn(color="primary", @click="signup") Sign Up
    v-snackbar(v-model="snackbar", :timeout="4000") {{ messageText }}
</template>

<script>
import PowerTrip from "../services/api/PowerTrip.js";
import Vue from "vue";
import axios from 'axios';
export default {
  props: ["dealerMode"],
  data() {
    return {
      messageText: "",
      snackbar: false,
      valid: true,
      loggedin: false,
      userName: "",
      email: "",
      password: "",
      confirmPassword: "",
      phone: "",
      rules: {
        required: (value) => !!value || "Required.",
        minimum: (value) => value.length >= 8 || "Minimum 8 Characters",
        tiny: (value) => value.length >= 8 || "Minimum 3 Characters",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        confirmPassword: (value) => value === this.password || 'The password confirmation does not match.'
        
      },
 
    };
  },
  mounted() {
    localStorage.removeItem("servicecode");
    localStorage.removeItem("credentials");
  },
  computed: {
    nameLabel() {
      return this.dealerMode ? "Customer Name" : "Your Name"
    },
    emailLabel() {
      return this.dealerMode ? "Customer Email" : "Your Email"
    }
  },
  methods: {
    back() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },
    login() {
      this.$router.push("/login");
    },
    async signup() {
      if (this.$refs.form.validate()) {
        var data = {
          email: this.email,
          password: this.password,
          name: this.userName,
          phone: this.phone,
        };
        var results = await PowerTrip.newAccount(data);
        if (results.status == "ok") {
          if (this.dealerMode) {
          this.snackbar = true;
          this.messageText =
            "Account Created for " + this.userName;
            this.email = "";
            this.password = "";
            this.userName = "";
            this.phone = "";

            return
          }
          var result = await PowerTrip.login(data);
          if (result.id) {
            // store token locally
            Vue.prototype.$user = result;
           // Vue.prototype.$auth = data;
            localStorage.setItem("token",result.token);
            axios.defaults.headers.common["X-Auth-Powertrip"] = result.token;
             this.$router.push("/wizard");
          }
        } else {
          this.snackbar = true;
          this.messageText =
            "Unable to create account - do you already have an account?";
        }
      }
    },
  },
};
</script>

<style scoped>
@import "../../node_modules/vue-phone-number-input/dist/vue-phone-number-input.css";
</style>